(function() {

    var submitAjaxRequestWithReturnOnTextarea = function(e) {

        var form = $(this);
        var method = form.find('input[name="_method"]').val() || 'POST';
        var textarea = form.find('textarea[name=inputOutputArea]');

        $.ajax({
            type: method,
            url: form.prop('action'),
            data: form.serialize(),
            success: function(result) {
                textarea.val(result);

                /*
                 * This will trigger $.subscribe('form.submitted', function() on allsites.js
                 */
                $.publish('form.submitted', form);
            },
            statusCode: {
                422: function(result) {
                    var validationError = result.responseJSON.inputOutputArea;
                    $('.notification').html(validationError).fadeIn(500).delay(1000).fadeOut(500);
                }
            }
        });


        $.publish('reset-notification-msg');

        //Prevent Default action of submitting the form
        e.preventDefault();
    };

    var submitAjaxRequest = function(e) {

        var form = $(this);
        var method = form.find('input[name="_method"]').val() || 'POST';

        $.ajax({
            type: method,
            url: form.prop('action'),
            data: form.serialize(),
            success: function(result) {
                $.publish('form.submitted', form);

            }
        });

        //Prevent Default action of submitting the form
        e.preventDefault();
    };

    //Form marked with "data-remote-return-on-textarea" and textarea with inputOutputArea name will
    //submit via AJAX and output returned to the textarea
    $('form[data-remote-return-on-textarea]').on('submit', submitAjaxRequestWithReturnOnTextarea);

    //Form marked with the "data-remote" attribute will submit, via AJAX
    $('form[data-remote]').on('submit', submitAjaxRequest);

})();